/* eslint-disable */
export const Search = {
  data() {
    return {
      orderStatus: '',
      filter: {
        size: 10,
        session_year: new Date().getFullYear(),
        key: "",
        key1: "",
        type: "",
        branch: "",
        account: "",
        accountType: "",
        model: "",
        method: "",
        assignedto: "",
        from: null,
        to: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
        day: new Date().getDate(),
        full_day: null,
        month: new Date().getMonth() + 1,
        full_month: null,
      },
      dataSets: [],
    };
  },
  methods: {
    filterData() {
      let tr, td, i, j, txtValue;
      tr = document.getElementById("tableData").getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        for (j = 0; j < tr[i].getElementsByTagName("td").length; j++) {
          td = tr[i].getElementsByTagName("td")[j];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (
              txtValue.toUpperCase().indexOf(this.filter.key.toUpperCase()) > -1
            ) {
              tr[i].style.display = "";
              break;
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }
    },
    filterData1() {
      let tr, td, i, j, txtValue;
      tr = document.getElementById("tableData1").getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        for (j = 0; j < tr[i].getElementsByTagName("td").length; j++) {
          td = tr[i].getElementsByTagName("td")[j];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (
              txtValue.toUpperCase().indexOf(this.filter.key1.toUpperCase()) >
              -1
            ) {
              tr[i].style.display = "";
              break;
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }
    },
    filterFromTo() {
      if (this.filter.from && this.filter.to) {
        this.filterByTwoDates();
      }
    },
    prevDay() {
      this.filter.day = this.filter.day - 1;
    },
    nextDay() {
      this.filter.day = this.filter.day + 1;
    },
    toDay() {
      this.filter.day = new Date().getDate();
      this.filterDay();
    },
    prevMonth() {
      this.filter.month = this.filter.month - 1;
    },
    nextMonth() {
      this.filter.month = this.filter.month + 1;
    },
    thisMonth() {
      this.filter.month = new Date().getMonth() + 1;
      this.filterMonth();
    },
    filterDay() {
      this.filter.full_day = `${new Date().getFullYear()}-${new Date().getMonth() +
        1}-${this.filter.day}`;
      this.filterByDay();
    },
    filterMonth() {
      this.filter.full_month = `${new Date().getFullYear()}-${
        this.filter.month
      }`;
      this.filterByMonth();
    },
  },
  watch: {
    dataLists(value) {
      if (value) {
        this.dataSets = value;
        // if(this.orderStatus == 'all'){
        //   this.filter.type = this.orderStatus;
        // }else{
        //   this.filter.type = value[0]['status'];
        // }
      }
    },
    "filter.key"() {
      this.filterData();
    },
    "filter.key1"() {
      this.filterData1();
    },
    "filter.type"(value) {
      if (value) {
        this.filterByType();
      } else {
        this.dataSets = this.dataLists;
      }
    },
    "filter.branch"(value) {
      if (value) {
        this.filterByBranch();
      } else {
        this.dataSets = this.dataLists;
      }
    },
    "filter.method"(value) {
      if (value) {
        this.filterByMethod();
      } else {
        this.dataSets = this.dataLists;
      }
    },
    "filter.assignedto"(value) {
      if (value) {
        this.filterByAssignedto();
      } else {
        this.dataSets = this.dataLists;
      }
    },
    "filter.size"() {
      if (this.filter.from && this.filter.to) {
        this.filterByTwoDates();
      } else {
        this.getData();
      }
    },
    "filter.session_year"() {
      this.getData();
    },
    "filter.from"() {
      this.filterFromTo();
    },
    "filter.to"() {
      this.filterFromTo();
    },
    "filter.day"(value) {
      let last_day = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getDate();
      if (value < 1) {
        this.filter.day = last_day;
      } else if (value > last_day) {
        this.filter.day = 1;
      }
      this.filterDay();
    },
    "filter.month"(value) {
      if (value < 1) {
        this.filter.month = 12;
      } else if (value > 12) {
        this.filter.month = 1;
      }
      this.filterMonth();
    },
    "filter.accountType"(value) {
      if (value) {
        this.filterByAccountType();
      } else {
        this.dataSets = this.dataLists;
      }
    },
    "filter.account"(value) {
      if (value) {
        this.filterByAccount();
      } else {
        this.dataSets = this.dataLists;
      }
    },
    "filter.model"(value) {
      if (value) {
        this.filterByModel();
      } else {
        this.dataSets = this.dataLists;
      }
    },
  },
};
